import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { ImprintPageTemplate } from '../templates/imprint-template'

const ImprintPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <ImprintPageTemplate
      imprintText = { frontmatter.imprintText }
    />
  )
}

ImprintPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  })
}

export default ImprintPage

export const imprintPageQuery = graphql`
query ImprintTemplate {
  markdownRemark(frontmatter: { templateKey: { eq: "imprint-page"}}) {
    frontmatter {
      
      imprintText      
    }
  }
}
`