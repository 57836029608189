import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import ImprintText from '../components/ImprintText'
import Layout from '../components/Layout'
import Spacer from '../components/Spacer'

export const ImprintPageTemplate = ({
  imprintText,
}) => (
    <div>
      <Helmet title="Impressum" defer={false}/>
      <Layout>
        <Spacer height={60} id="top"/>
        <ImprintText data= {imprintText}/>
        <Spacer height={30}/>
      </Layout>
    </div>
)

ImprintPageTemplate.propTypes = {
  imprintText: PropTypes.object,
}